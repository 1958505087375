import React from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Logo from "../components/logo.svg"
import { mergeClasses } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core/styles"
import Footer from "../components/footer"

const styles = makeStyles(theme => ({
  titleText: {
    textAlign: "center",
  },
}))

export default function About(props) {
  const classes = styles()
  return (
    <Layout>
      <SEO />
      <Grid container>
        <h1 className={classes.titleText}>About Explore Personal Finance</h1>
        <p>
          This site is written by Katie and Matt, a married couple living in
          Seattle. We just turned 30, and finally feel like we have a good
          handle on our finances. But it wasn't easy! It took us most of our 20s
          to gradually understand the tax system, retirement accounts,
          investing, and so much more. When others asked us for resources to
          learn the same things, we looked around and couldn't quite find all of
          this information in one place. So, we built a website!
        </p>
        <p>
          We believe that personal finance knowledge should be accessible to
          anyone and organized in an easy to follow format that will guide the
          reader through what they need to know. We also think it is useful to
          take a stand and be opinionated on which strategies make sense to
          follow, given the research, and which do not.
        </p>
        <p>
          We are sharing what we've learned with you, and we hope it helps. If
          you want to share with us, or give us feedback, please{" "}
          <a href="mailto:contactus@explorepersonalfinance.com">email us!</a>
        </p>
      </Grid>
      <Footer />
    </Layout>
  )
}
